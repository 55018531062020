.PostInput {
  text-align:justify;
  color:#20c20e;
  padding-left:8px;
  padding-right:8px;


}

.CommentsContent {
  text-align:left;
  color:green;
  width:300;

}
