body {
  background-color: #020005;
}

h1 {
  color: #701b1d;
  text-align:left;
  margin-left:16px;
}

p{
  color: #601b1d;
  text-align:left;
  margin-left:16px;
}

.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.PostInput {
  width: 100%;
  color:#20c20e;


}

.PostContainer {

  color:#EDF5E1;
}

.PostContainer {
  color:#20c20e;
  padding-left:8px;
  padding-right:8px;
}


.Content{



}
.Content:hover{
  background-color:#28283F;
  cursor: pointer;

}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.PostInput {
  text-align:justify;
  color:#20c20e;
  padding-left:8px;
  padding-right:8px;


}

.CommentsContent {
  text-align:left;
  color:green;
  width:300;

}

.LoginInput {
  width: 100%;
  color:#20c20e;

}

.LoginContainer {
  width: 300px;
  color:#EDF5E1;
}

.RegisterInput {
  width: 100%;
  color:#20c20e;


}

.RegisterContainer {
  width: 300px;
  color:#EDF5E1;
}

