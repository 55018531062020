.LoginInput {
  width: 100%;
  color:#20c20e;

}

.LoginContainer {
  width: 300px;
  color:#EDF5E1;
}
