.PostInput {
  width: 100%;
  color:#20c20e;


}

.PostContainer {

  color:#EDF5E1;
}
