.PostContainer {
  color:#20c20e;
  padding-left:8px;
  padding-right:8px;
}


.Content{



}
.Content:hover{
  background-color:#28283F;
  cursor: pointer;

}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}
