.RegisterInput {
  width: 100%;
  color:#20c20e;


}

.RegisterContainer {
  width: 300px;
  color:#EDF5E1;
}
